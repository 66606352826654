@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%); /* This should be the height of a single card */
  }
}

.scrolling-cards {
  animation: scroll 90s linear infinite; /* Adjust the time as necessary */
}


@keyframes scrollHorizontal {
  0% {
    transform: translateX(0);
  }
  100% {
    /* This should be the width of a single card times the number of cards */
    transform: translateX(calc(-1 * var(--card-width) * var(--num-cards)));
  }
}

@keyframes scrollHorizontal {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-2500px); /* 10 cards * 250px per card */
  }
}


.scrolling-cards-horizontal {
  display: flex;
  flex-direction: row;
  animation: scrollHorizontal 60s linear infinite; /* Duration of the animation */
  white-space: nowrap; /* Prevent wrapping of inline elements */
}

.scrolling-cards-container-horizontal {
  overflow-x: hidden;
  width: 100%; /* Full width of the container */
}

/* Phone input */
.cruwi-phone {
  background-color: white;
  width: 100%;
}

.cruwi-phone .react-international-phone-input {
  height: 32px;
  width: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-color: #e2e8f0;
}

.cruwi-phone .react-international-phone-country-selector .react-international-phone-country-selector-button {
  height: 32px;
  min-width: 50px;
  border-color: #e2e8f0;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}
